import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  createChart,
  IChartApi,
  ISeriesApi,
  LineData,
  WhitespaceData,
} from 'lightweight-charts'
import { parseMessage } from 'lib/messages'
import { LineChartMessages } from '@cex/shared-modules'
import { lineTheme } from '../theme'
import './styles.module.css'

const Line: React.FC = () => {
  const chartElRef = useRef<HTMLDivElement | null>(null)
  const chartRef = useRef<IChartApi | null>(null)
  const seriesRef = useRef<ISeriesApi<'Line'> | null>(null)

  const [chartData, setChartData] = useState<(WhitespaceData | LineData)[]>()

  const resetChartSize = useCallback(() => {
    if (chartRef.current) {
      chartRef.current.resize(window.innerWidth, window.innerHeight)
      chartRef.current.timeScale().fitContent()
    }
  }, [])

  const subscribeToMessages = useCallback(
    (e) => {
      const data: LineChartMessages = parseMessage<LineChartMessages>(e.data)

      switch (data.type) {
        case 'setChartData':
          setChartData(data.payload)
          resetChartSize()
          return

        case 'updateChartData':
          if (seriesRef.current) {
            try {
              seriesRef.current.update(data.payload)
              resetChartSize()
            } catch {}
          }
          return
      }
    },
    [resetChartSize]
  )

  if (chartElRef.current !== null) {
    if (!chartRef.current) {
      chartRef.current = createChart(chartElRef.current, {
        width: window.innerWidth,
        height: window.innerHeight,
        ...lineTheme.chart
      })
    }
  }

  useEffect(() => {
    if (chartRef.current === null) {
      return
    }

    if (seriesRef.current) {
      chartRef.current.removeSeries(seriesRef.current)
    }

    seriesRef.current = chartRef.current.addLineSeries(lineTheme.series)

    if (chartData) {
      seriesRef.current.setData(chartData)
      chartRef.current.timeScale().fitContent()
    }
  }, [chartData, resetChartSize])

  useEffect(() => {
    window.addEventListener('resize', resetChartSize)
    window.addEventListener('message', subscribeToMessages)
    return () => {
      window.removeEventListener('resize', resetChartSize)
      window.removeEventListener('message', subscribeToMessages)
    }
  }, [resetChartSize, subscribeToMessages])

  return <div ref={chartElRef}></div>
}

export default React.memo(Line)
