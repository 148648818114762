import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Candlestick from 'components/Charts/Candlestick'
import Line from 'components/Charts/Line'
import Portfolio from 'components/Charts/Portfolio'
import './App.module.css'

function App() {
  return (
    <Router>
      <Route exact path="/candlestick" component={Candlestick} />
      <Route exact path="/line" component={Line} />
      <Route exact path="/portfolio" component={Portfolio} />
    </Router>
  )
}

export default App
