import { GenericChartMessage } from '@cex/shared-modules/lib/types/webview-charts'

export function parseMessage<T>(msg: string): T | GenericChartMessage {
  try {
    const msgData = JSON.parse(msg)
    return msgData
  } catch {}
  return {
    type: 'unknown',
  }
}
