import { LineWidth } from 'lightweight-charts'

export const commonTheme = {
  chart: {
    layout: {
      backgroundColor: '#ffffff',
      lineColor: '#6432FA',
      textColor: '#D9D9D9',
    },
    watermark: {
      color: 'rgba(0, 0, 0, 0)',
    },
    grid: {
      vertLines: {
        color: 'transparent',
      },
      horzLines: {
        color: 'transparent',
      },
    },
    handleScale: false,
    handleScroll: false,
    timeScale: {
      fixLeftEdge: true,
      timeVisible: true,
      borderColor: '#D9D9D9',
    },
    rightPriceScale: {
      borderColor: '#D9D9D9',
    },
  },
}

export const candlestickTheme = {
  ...commonTheme,
}

export const lineTheme = {
  ...commonTheme,
  series: {
    color: '#6432FA',
    lineWidth: 1 as LineWidth,
  },
}

export const portfolioTheme = {
  ...commonTheme,
  chart: {
    ...commonTheme.chart,
    rightPriceScale: {
      visible: false
    },
  },
  series: {
    color: '#6432FA',
    lineWidth: 3 as LineWidth,
    priceLineVisible: false
  },
}

export const areaTheme = {
  ...commonTheme,
  series: {
    topColor: 'transparent',
    bottomColor: 'transparent',
    lineColor: '#6432FA',
  },
}
