import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  BarData, createChart, IChartApi, ISeriesApi,
  TimeRangeChangeEventHandler, UTCTimestamp,
} from 'lightweight-charts'
import { parseMessage } from 'lib/messages'
import { CandlestickMessages, TradingViewCandle } from '@cex/shared-modules'
import { candlestickTheme } from '../theme'
import './styles.module.css'

interface ItemsBySeries {
  candles: BarData[]
  line: BarData[]
}

function getItemsBySeries (items: TradingViewCandle[]): ItemsBySeries {
  const candles: BarData[] = []
  const line: BarData[] = []

  for (const item of items) {
    const { volume, time, ...bar } = item

    const formatted: BarData = {
      ...bar,
      time: time as UTCTimestamp
    }

    if (volume) {
      candles.push(formatted)
    } else {
      line.push(formatted)
    }
  }

  return {
    candles,
    line
  }
}

const Candlestick: React.FC = () => {
  const chartElRef = useRef<HTMLDivElement | null>(null)
  const chartRef = useRef<IChartApi | null>(null)

  const candleSeriesRef = useRef<ISeriesApi<'Candlestick'> | null>(null)
  const lineSeriesRef = useRef<ISeriesApi<'Candlestick'> | null>(null)

  const [chartData, setChartData] = useState<TradingViewCandle[]>()

  const resetChartSize = useCallback(() => {
    if (chartRef.current) {
      chartRef.current.resize(window.innerWidth, window.innerHeight)
      chartRef.current.timeScale().fitContent()
    }
  }, [])

  const subscribeToMessages = useCallback(
    (e) => {
      const data: CandlestickMessages = parseMessage<CandlestickMessages>(
        e.data
      )

      switch (data.type) {
        case 'setChartData':
          setChartData(data.payload)
          resetChartSize()
          return

        case 'updateChartData':
          if (candleSeriesRef.current) {
            try {
              candleSeriesRef.current.update(data.payload)
              resetChartSize()
            } catch {}
          }
          return
      }
    },
    [resetChartSize]
  )

  if (
    chartElRef.current !== null &&
    typeof chartElRef.current !== 'undefined'
  ) {
    if (!chartRef.current) {
      chartRef.current = createChart(chartElRef.current, {
        width: window.innerWidth,
        height: window.innerHeight,
        ...candlestickTheme.chart
      })
    }
  }

  const timeChangeHandler = useCallback<TimeRangeChangeEventHandler>(() => {
    // TODO: to be implemented
  }, [])

  useEffect(() => {
    if (chartRef.current === null) {
      return
    }

    if (lineSeriesRef.current) {
      chartRef.current.removeSeries(lineSeriesRef.current)
      chartRef.current
        .timeScale()
        .unsubscribeVisibleTimeRangeChange(timeChangeHandler)
    }

    if (candleSeriesRef.current) {
      chartRef.current.removeSeries(candleSeriesRef.current)
      chartRef.current
        .timeScale()
        .unsubscribeVisibleTimeRangeChange(timeChangeHandler)
    }

    candleSeriesRef.current = chartRef.current.addCandlestickSeries()

    lineSeriesRef.current = chartRef.current?.addCandlestickSeries({
      upColor: '#777',
      wickVisible: false,
      borderVisible: false,
      priceLineVisible: false,
      lastValueVisible: false
    })

    if (chartData) {
      const {
        candles,
        line
      } = getItemsBySeries(chartData)

      candleSeriesRef.current.setData(candles)
      lineSeriesRef.current.setData(line)
      chartRef.current.timeScale().fitContent()
      chartRef.current
        .timeScale()
        .subscribeVisibleTimeRangeChange(timeChangeHandler)
    }
  }, [chartData, resetChartSize, timeChangeHandler])

  useEffect(() => {
    window.addEventListener('resize', resetChartSize)
    window.addEventListener('message', subscribeToMessages)
    return () => {
      window.removeEventListener('resize', resetChartSize)
      window.removeEventListener('message', subscribeToMessages)
    }
  }, [resetChartSize, subscribeToMessages])

  return <div ref={chartElRef}></div>
}

export default React.memo(Candlestick)
